.collapseButtonsDiv{
    display:flex;
    align-items: flex-start;
}

.grouptitlediv {
	background-color: #1e4279;
    font-size:1.5em;
}

.groupdiv{
    background-color:rgb(102, 102, 102);
    display:flex;
    flex-direction: column;
    max-width:70vh;
}

.vertgroup{
    border:2px solid white;
}

.employesVertGroup{
    width:60vh
}

.horizVertGroups{
    display:flex;
    flex-direction:row;
    margin:auto;
    width:fit-content;
}
.responseElement{
    margin-left: auto;
    margin-right: auto;
    margin-top:100px;
    height:fit-content;
    width: fit-content;
}

.centeredHorizontalDiv{
    display:flex;
    flex-direction:row;
    padding:5px;
    height:fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    flex-wrap:wrap;
}
.centeredHorizontalDiv > *{
    margin-left: 10px;
}

.horizontalDivWithBorder{
    display:flex;
    flex-direction:row;
    border: 1px solid white;
    padding-left:5px;
    padding-right: 5px;
    height:fit-content;
    width: fit-content;
    padding-top:1px;
}

.horizontalDivWithBorder > *{
    border-top:auto;
    align-self:center;
}

.pWithHeight{
    height:50px
}
.table{
    table-layout: auto;
    border-collapse: collapse;
}
.tabletdtr{
    border: 3px solid white;
    border-collapse: collapse;
    background-color: grey;
}
