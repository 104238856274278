.recapColMoment{
    width:78px;
    min-width:78px;
}

.recapColHeures{
    width:60px;
    min-width:60px;
}

.recapColChantier{
    width:70px;
    min-width:70px;
}
.recapColRemarques{
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
    min-width: 92px;
    width:92px;
    max-width: 92px;
}
.recapColLieu{
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
    max-width:82px;
    width:82px;
    min-width:82px
}
.recapColTrajet{
    min-width:210px;
    max-width:210px;
}
.recapColTrajetElement{
    min-width:30px;
    max-width:30px;
    width:30px;
    padding-left:0px !important;
    padding-right:0px !important;
}
.recapColVehicule{
    width:93px;
    min-width:93px;
}
.recapColPanier{
    width:55px;
    max-width:55px;
    min-width:55px;
}
.recapColAbsence{
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
    max-width:98px;
    min-width:98px
}
.recapColJourEntAbsence{
    width:60px
}


.horizontalFlex{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.midDayMargins{
    margin-left: 5px;
    margin-right: 5px;
}
.dayArrayRibbon{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
}
.dayArrayRibbon > *{
    height:100%
}
/*.button{
    background-color: #ffffff;
}*/

.button{
    box-shadow:inset 0px 0px 15px 3px #5279bb;
	background:linear-gradient(to bottom, #506ea1 5%, #6881b6 100%);
	background-color:#405f96;
	border-radius:17px;
	border:1px solid #3b5885;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-weight:normal;
	text-decoration:none;
	text-shadow:0px 1px 0px #263666;
}

.button:hover {
	background:linear-gradient(to bottom, #76a3fc 5%, #527dc9 100%);
	background-color:#415989;
}

.button:disabled{
    background:linear-gradient(to bottom, #586274 5%, #8f98ac 100%);
	background-color:#646c7a;
}

.wide{
    width: 100%;
}

.statusDefault{
    background-color:#ffffff
}

.statusUnmodified{
    background-color:#809b80
}

.statusModified{
    background-color:#a5a5a5
}

.statusSaved{
    background-color:#00a116
}

.statusSaveError{
    background-color:#7e2e2e
}

.dayDefault{
    background:linear-gradient(to bottom, #253858 20%, #415989 100%);
}
.daySaved{
    background:linear-gradient(to bottom, #253858 20%, #415989 100%);
}

.daySaveError{
    background-color:#816969
}

.dayStyle{
    margin-right: 10px;
    margin-left:10px;
    width: 340px;
    
	background-color:#2e466e;
    color:#ffffff;
    display:flex;
    flex-direction:column;
    /*display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 10fr;*/
}

.dayDivElementStyle{
    width:90%;
    margin: auto;
}

.dayLabelStyle{
    float:left;
}

.dayInputStyle{
    float:right;
    width:40%;
}

.codeChantier{
    width:50px;
    margin-left:10px
}

.timeInput{
    width:40px
}
.dayTitle{
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: larger;
}

.halfDayStyle{
    background-color: rgba(255, 255, 255, .1);
    margin-top: 4px;
    margin-bottom: 4px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.halfDayTitle{
    margin-top: 6px;
    margin-bottom: 6px;
    font-weight: bold;
}
.halfDayTimeSlotsStyle{
    list-style-type:none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.timeSlotStyle{
    width:100%;
    display: flex;
    flex-direction: line;
    margin:auto;
}

.timeSlotFieldsStyle{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top:2px;
    padding-bottom:2px;
    padding-right:0px;
    padding-left:0px;
    margin:0px;
}
.dayArrayOuterStyle{
    display: block;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.dayArrayInnerStyle{
    display: flex;
    list-style-type:none;
    width: fit-content;
}

.saveAllButton{
    height: 4vh;
}

.tooltip{
    position: relative;
    background: rgba(35, 33, 131, 0.3);
    padding: 5px 12px;
    border-radius: 100%;
    font-size: 20px;
    cursor: help;
}

.tooltip::before, .tooltip::after{
    position: absolute;
    left: 50%;
    opacity: 0;
    }

.tooltip::after{
    content: attr(data-tooltip);
    background: rgba(0,0,0,0.3);
    top: -20px;
    transform: translateY(-100%);
    font-size: 14px;
    margin-left: -150px;
    width: 300px;
    border-radius: 10px;
    color: #fff;
    padding: 14px;
}

.tooltip:hover::before.tooltip:hover::after{
    Opacity:1;
    }

.recaparray{
    display:flex;
    flex-direction:column;
}

.recaptable{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    table-layout: fixed;
    margin-bottom:10px;
}

/*@media print {
    .recaptable{
        white-space: nowrap;
    }
}*/
.recaptable td, .recaptable th {
    border: 1px solid #ddd;
    padding: 2px;
}

.recaptable tr{
    background-color: #ffffff;
    color:black;
}

.recaptable tr:nth-child(even){background-color: #e6e6e6;}

.dayTableTitleTH{
    text-align:left;
}
.recaptable th {
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #656270;
    color: white;
}

.dateth{
    background-color: #494166 !important;
}

@media print{
    .dayInputs{
        display:none;
    }
}

.onlyDisplayOnPrint{
    display:none;
}
@media print{
    .onlyDisplayOnPrint{
        display:block;
    }
}