.dashButton {
	box-shadow:inset 0px 0px 15px 3px #23395e;
	background:linear-gradient(to bottom, #2e466e 5%, #415989 100%);
	background-color:#2e466e;
	border-radius:17px;
	border:1px solid #1f2f47;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	font-weight:normal;
	padding:15px 44px;
	text-decoration:none;
	text-shadow:0px 1px 0px #263666;
}

.dashButton:hover {
	background:linear-gradient(to bottom, #415989 5%, #2e466e 100%);
	background-color:#415989;
}

.dashButton:active {
	position:relative;
	top:1px;
}
