h1 {
  font-weight: 500;
}

h2 {
  font-weight: 300;
}

.App {
  text-align: center;
  font-family: Arial, Helvetica, Liberation Sans, FreeSans, sans-serif;
}


.App-logo {
  pointer-events: none;
}

.App-header {
	background:linear-gradient(to bottom, #253858 20%, #415989 100%);
	background-color:#2e466e;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  font-size: 2vh;
  color: white;
  padding: 0px;
  margin: 0px;

  
}

@media print{
  .App-header {
    display: none;
  }
}

.Main-page{
	background:linear-gradient(to bottom, #455f8b 20%, #5a74a7 100%);
	background-color:#2e466e;
  color: white;
  height: 100vh;
  padding-top: 4vh;
  margin: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
