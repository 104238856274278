.personSelectorDiv{
    height: fit-content;
}

@media print{
    .personSelectorDiv{
        display: none;
    }
}
.personSelector{
    background-color:#ffffff;
    height: fit-content;
}

.whiteBackground{
    background-color:#ffffff;
}


.whiteButton{
    background-color:#ffffff;
}

.bigwhiteButton{
    background-color:#ffffff;
    font-size: 25px;
    margin:30px;
}

.blueInput{
    border:1px solid #1e4279;
    background-color:#5279bb;
    color:#ffffff;
}

.blueInput:disabled{
    background-color:#7b889e;
}
.button{
    box-shadow:inset 0px 0px 15px 3px #5279bb;
	background:linear-gradient(to bottom, #506ea1 5%, #6881b6 100%);
	background-color:#405f96;
	border-radius:17px;
	border:1px solid #3b5885;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-weight:normal;
	text-decoration:none;
	text-shadow:0px 1px 0px #263666;
}

.button:hover {
	background:linear-gradient(to bottom, #76a3fc 5%, #527dc9 100%);
	background-color:#415989;
}

.CRlistdiv{
    max-width: 80%;
}
.CRlist{
    display:flex;
    flex-wrap:wrap; 
    max-width: 80%;
}

.CRdiv{
    border:solid;
}