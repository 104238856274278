.grouptitlediv {
	background-color: #1e4279;
    font-size:1.5em;
}

.groupdiv{
    background-color:rgb(207, 207, 207);
    display:flex;
    flex-direction: column;
}

.vertgroup{
    width:max-content;
    border:2px solid white;
}